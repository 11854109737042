<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3"
        ><v-icon color="primary" left>
          mdi-frequently-asked-questions
        </v-icon>
        Solicitações de Serviço
        <v-spacer></v-spacer>
       
      </v-card-title>

      <v-tabs fixed-tabs v-model="tab">
        <v-tab>Não Lidas</v-tab>
        <v-tab>Lidas</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <SolicitacoesServicos v-if="tab === 0" :status="0" :key="tab" />
        </v-tab-item>

        <v-tab-item>
          <SolicitacoesServicos v-if="tab === 1" :status="1" :key="tab" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "SolicitacoesServicosTabs",

  data() {
    return {
      tab: 0,
    };
  },

  components: {
    SolicitacoesServicos: () => import("./SolicitacoesServicos.vue"),
 
  },
};
</script>

<style></style>
